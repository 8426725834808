import React from 'react';
import { Button } from 'antd';
import styles from './PartsView.module.scss';

interface CSVDownloaderProps {
  partName: string;
  location: Location | any; // Browser location object
  getCSV: (query: string) => Promise<string>; // Function to fetch CSV data
  setPercent: any;
  handleCancel: any;
  showModal: any;
}

const CSVDownloader: React.FC<CSVDownloaderProps> = ({
  partName,
  location,
  getCSV,
  setPercent,
  handleCancel,
  showModal,
}) => {
  const handlecdvdownload = async (): Promise<void> => {
    try {
      setPercent(0);

      const typeString = `t=${partName}`;
      const isIncluded = location.search.includes(typeString);

      const progressInterval = setInterval(() => {
        setPercent((prev: any) => (prev < 90 ? prev + 5 : prev));
      }, 50);

      const data = await getCSV(
        `${location.search}${isIncluded ? '' : `${location.search ? '&' : '?'}${typeString}`}`,
      );

      clearInterval(progressInterval);
      setPercent(100);

      const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });

      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = `${partName}.csv`;
      link.style.display = 'none';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    } finally {
      setPercent(100);

      setTimeout(() => {
        setPercent(0);
        handleCancel();
      }, 1000);
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Button
        onClick={() => {
          handlecdvdownload();
          showModal();
        }}
        className={styles.addItemButton}
      >
        Download CSV
      </Button>
    </div>
  );
};

export default CSVDownloader;
