import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Papa, { ParseResult, unparse } from 'papaparse';
import { Button, List, message, Modal, Pagination, Popover, Typography } from 'antd';
import styles from './PartsView.module.scss';
import { AppState } from '~/store/reducers';
import { getParts, selectPartByPartName } from '~/utils/parts';
import { isStateLoading } from '~/utils/state';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { putUploadCSV } from '~/api/AuthorizedPuts';

interface CSVData {
  [key: string]: string | any;
}
function findAndReplaceDynamic(array: any[], findValue: string, replaceValue: string) {
  return array.map((obj) => {
    const updatedObj = { ...obj };
    for (const key in obj) {
      if (obj[key] === findValue) {
        updatedObj[key] = replaceValue;
      }
    }
    return updatedObj;
  });
}

function findValueAndMark(array: any[], findValue: unknown) {
  return array.map((obj) => {
    const found = Object.values(obj).includes(findValue);
    return found ? { ...obj, valueFound: true } : obj;
  });
}

const CSVReader = ({ parts }: { parts: any[] }) => {
  const [data, setData] = useState<CSVData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(50);
  const [open, setOpen] = useState(false);
  const pageSizeOptions = [5, 10, 20, 50, 100, 200, 500];
  const [keysArray, setKeysArray] = useState<any>([]);
  const [selectedFilter, setSelectedFilter] = useState<any>('');
  const [valueFind, setValueFind] = useState<string>('');
  const [valueReplace, setValueReplace] = useState<string>('');
  const [currentItems, setCurrentItems] = useState([]);

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const params = useParams();
  const partName = params.partName;

  const part = useMemo(() => {
    if (!parts) return null;
    return selectPartByPartName(parts, partName || '');
  }, [parts, partName]);

  const options = useMemo(() => {
    if (!part) return;

    if (!part.write_schema) return;
    return JSON.parse(part.write_schema);
  }, [part]);
  const schemaToArray = useCallback(
    (schema: any) => {
      if (!schema || typeof schema !== 'object') {
        throw new Error('Invalid schema object');
      }
      const result: any[] = [];
      Object.entries(schema).forEach(([key, value]: any) => {
        const field = {
          key,
          type: value?.enum ? 'enum' : value.type || null,
          title: value.title || key,
          enum: value?.enum,
        };
        result.push(field);
        if (value.type === 'array' && value.items) {
          const itemKeyPrefix = `${key}/`;

          if (value.items.type === 'object' && value.items.properties) {
            Object.entries(value.items.properties).forEach(([subKey, subValue]: any) => {
              result.push({
                key: `${itemKeyPrefix}${subKey}`,
                type: subValue?.enum ? 'enum' : subValue.type || null,
                title: subValue.title || subKey,
                enum: subValue?.enum,
              });
            });
          } else {
            result.push({
              key: `${itemKeyPrefix}item`,
              type: value.items?.type || null,
              title: value.items.title || `${key} item`,
              enum: value.items?.enum,
            });
          }
        }
      });
      const required_array = options?.required;
      const finalResult = result.map((item) => {
        return {
          ...item,
          required: required_array.find((val: any) => val === item.key) ? true : false,
        };
      });

      return finalResult;
    },
    [options?.required],
  );

  const headerArray = useMemo(() => {
    if (!options?.properties) return;
    return schemaToArray(options?.properties);
  }, [options?.properties, schemaToArray]);

  const handleReset = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const checkTrue = (value: string | any) => {
    return value === 'TRUE' ? true : value === 'FALSE' ? false : value;
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0];

    if (!file) {
      alert('Please upload a valid CSV file.');
      return;
    }

    Papa.parse<CSVData>(file, {
      header: true,
      skipEmptyLines: true,
      chunk: (results: ParseResult<CSVData>) => {
        const allKeys = Object.keys(results.data[0]);
        const finalKeysArr = allKeys.map((item, index) => {
          return {
            key: item,
            validation: '',
            id: index + 1,
          };
        });
        setKeysArray(finalKeysArr);
        const csvData = results.data.map((item) => {
          const trimmedItem = Object.fromEntries(
            Object.entries(item).map(([key, value]) => [
              key,
              typeof value === 'string' ? checkTrue(value.trim()) : checkTrue(value), // Trim strings only
            ]),
          );

          const hasEmptyValue = Object.values(trimmedItem).some(
            (value) => value === '' || value === null || value === undefined,
          );

          return {
            ...trimmedItem,
            error: hasEmptyValue,
            solder_cups: checkTrue(trimmedItem.solder_cups),
            has_shell: checkTrue(trimmedItem.has_shell),
            molded_cable_whip: checkTrue(trimmedItem.molded_cable_whip),
          };
        });

        setData(csvData);
        setOpen(true);
      },
      error: (err: any) => {
        console.error('Error parsing CSV:', err);
      },
    } as any);
  };

  const handleCellChange = (rowIndex: number, key: string, value: string): void => {
    const updatedData = [...data];
    updatedData[rowIndex][key] = value?.trim();
    setData(updatedData);
  };

  const handlePageChange = (page: number): void => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (current: number, size: number): void => {
    setItemsPerPage(size);
    setCurrentPage(1);
  };

  const offset = (currentPage - 1) * itemsPerPage;
  // const currentItems = data.slice(offset, offset + itemsPerPage);

  useEffect(() => {
    const newItems = data.slice(offset, offset + itemsPerPage);
    setCurrentItems(newItems as any);
  }, [data, offset, itemsPerPage]);

  const onCancel = () => {
    setOpen(false);
    setData([]);
    setCurrentPage(1);
    handleReset();
  };

  const checkEnum = false;

  const validationTypes1 = [
    {
      id: 1,
      name: 'Real number',
      value: 'number',
    },
    {
      id: 4,
      name: 'Integer',
      value: 'number_integer',
      greaterThan: 0,
    },
    {
      id: 5,
      name: 'Whole number',
      value: 'whole_number',
      greaterThan: 0,
    },
  ];

  const validationTypes2 = [
    {
      id: 5,
      name: 'Pick List Validation',
      value: 'picklist',
    },
  ];

  const validationTypes3 = [
    {
      id: 1,
      name: 'Validate',
      value: selectedFilter.type,
    },
  ];

  const handleModalOk = () => {
    const csv = unparse(currentItems);

    const blob = new Blob([csv], { type: 'text/csv' });

    if (!blob) {
      alert('No file selected for upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', blob);

    putUploadCSV('', formData)
      .then(() => {
        message.success('Upload successful');
        onCancel();
        setOpen(false);
        setData([]);
      })
      .catch((error) => {
        console.error('Upload failed:', error);
      });
  };

  const content = (
    <>
      <div style={{}}>
        {selectedFilter?.type === 'enum' ? (
          <div>
            <List
              dataSource={selectedFilter?.enum}
              renderItem={(item: string) => {
                return (
                  <List.Item
                    style={{
                      padding: '3px',
                    }}
                  >
                    <Typography.Text>{item}</Typography.Text>
                  </List.Item>
                );
              }}
            />
          </div>
        ) : selectedFilter?.type !== 'number' && selectedFilter?.type !== 'integer' ? (
          <List
            dataSource={validationTypes3}
            renderItem={(item) => {
              return (
                <List.Item
                  style={{
                    padding: '3px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    const updatedArr = keysArray.map((it_em: any) => {
                      if (selectedFilter.key === it_em.key) {
                        it_em.validation = item?.value;
                        return it_em;
                      } else {
                        return it_em;
                      }
                    });
                    setKeysArray(updatedArr);
                    setSelectedFilter('');
                  }}
                >
                  <Typography.Text>{item.name}</Typography.Text>
                </List.Item>
              );
            }}
          />
        ) : (
          <List
            dataSource={checkEnum ? validationTypes2 : validationTypes1}
            renderItem={(item) => {
              return (
                <List.Item
                  style={{
                    padding: '3px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    const updatedArr = keysArray.map((it_em: any) => {
                      if (selectedFilter.key === it_em.key) {
                        it_em.validation = item?.value;
                        return it_em;
                      } else {
                        return it_em;
                      }
                    });
                    setKeysArray(updatedArr);
                    setSelectedFilter('');
                  }}
                >
                  <Typography.Text>{item.name}</Typography.Text>
                </List.Item>
              );
            }}
          />
        )}
      </div>
    </>
  );

  function validateField(value: any, validationType: any, pickList?: string[] | string) {
    const errors: any = [];

    validationType.forEach((type: any) => {
      switch (type?.value) {
        case 'number':
        case 'integer':
          if (typeof value !== 'number' || Number.isNaN(value)) {
            errors.push('Value is not a number.');
          } else if (type.greaterThan !== undefined && value <= type.greaterThan) {
            errors.push(`Number must be greater than ${type.greaterThan}.`);
          } else if (type.decimals !== undefined) {
            const decimalCount = value.toString().split('.')[1]?.length || 0;
            if (decimalCount > type.decimals) {
              errors.push(`Number must have at most ${type.decimals} decimal places.`);
            }
          }
          break;

        case 'number_0':
          if (typeof value !== 'number' || value < 1 || Number.isNaN(value)) {
            errors.push('Value is not a number.');
          }
          break;

        case 'number_0_decimal':
          if (typeof value !== 'number' || value < 1 || !Number.isInteger(value)) {
            errors.push('Value is not a number.');
          }
          break;

        case 'number_integer':
          if (typeof value !== 'number' || !Number.isInteger(value)) {
            errors.push('Value is not a number.');
          }
          break;

        case 'whole_number':
          if (typeof value !== 'number' || !Number.isInteger(value) || value <= 0) {
            errors.push('Value is not a number.');
          }
          break;

        case 'number_-1':
          if (typeof value !== 'number' || value < 0 || Number.isNaN(value)) {
            errors.push('Value is not a number.');
          }
          break;

        case 'link': {
          const httpsRegex = /^https:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/[^\s]*)?$/i;
          if (!httpsRegex.test(value)) {
            errors.push('Value is not a valid HTTPS link.');
          }
          break;
        }

        case 'text':
          if (typeof value !== 'string') {
            errors.push('Value is not a string.');
          }
          break;

        case 'string':
          if (typeof value !== 'string') {
            errors.push('Value is not a string.');
          }
          break;

        case 'boolean':
          if (typeof value !== 'boolean') {
            errors.push('Value is not a boolean.');
          }
          break;

        // case 'nospace':
        //   if (typeof value === 'string') {
        //     if (/^\s/.test(value) || /\s$/.test(value)) {
        //       errors.push('String cannot have leading or trailing spaces.');
        //     }
        //   } else {
        //     errors.push('Value is not a string for noSpaces validation.');
        //   }
        //   break;

        case 'enum':
        case 'picklist':
          if (Array.isArray(pickList)) {
            const checkIsExist = pickList.includes(value);

            if (!checkIsExist) {
              errors.push('Value is not in the picklist.');
            }
          } else {
            errors.push('Picklist validation requires an array.');
          }
          break;

        default:
          errors.push(`Unknown validation type: ${type?.value}`);
      }
    });

    return errors.length === 0 ? false : true;
  }

  function booleanConvert(value: any) {
    if (value === 'true') {
      return true;
    } else if (value === 'false') {
      return false;
    } else {
      return value;
    }
  }

  const isCheckFound = currentItems.some((item: any) => item?.valueFound);

  return (
    <div>
      <Button className={styles.addItemButton}>
        <input
          type="file"
          ref={fileInputRef}
          accept=".csv"
          onChange={handleFileUpload}
          style={{
            opacity: '0',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0px',
            right: '0px',
          }}
        />
        <p style={{ marginBottom: '0px' }}>Upload CSV</p>
      </Button>
      <Modal
        forceRender
        cancelText="Cancel"
        okText="Confirm"
        title=""
        width={'100%'}
        style={{
          padding: '0px',
          top: '30px',
          height: 'calc(100% - 60px)',
        }}
        styles={{
          content: {
            height: '100%',
            paddingTop: '40px',
          },
          footer: {
            marginTop: '0px',
          },
          body: {
            height: 'calc(100% - 34px)',
          },
        }}
        onOk={() => {
          handleModalOk();
        }}
        open={open}
        onCancel={onCancel}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            // paddingBottom: '18px',
            gap: '10px',
            padding: '18px 0px 14px',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '10px',
            }}
          >
            <input
              style={{}}
              placeholder="Enter value to find"
              value={valueFind}
              onChange={(event) => {
                setValueFind(event?.target?.value);
              }}
            />
            <Button
              disabled={!valueFind}
              onClick={() => {
                if (valueFind) {
                  setCurrentItems(findValueAndMark(currentItems, valueFind) as any);
                }
              }}
            >
              Find
            </Button>
          </div>
          {isCheckFound ? (
            <div
              style={{
                display: 'flex',
                gap: '10px',
              }}
            >
              <input
                style={{}}
                placeholder="Enter value to replace"
                value={valueReplace}
                onChange={(event) => {
                  setValueReplace(event?.target?.value);
                }}
              />
              <Button
                disabled={valueFind && valueReplace ? false : true}
                onClick={() => {
                  if (valueFind && valueReplace) {
                    const updatedArr = findAndReplaceDynamic(currentItems, valueFind, valueReplace);
                    if (updatedArr.length > 0) {
                      const newArr = updatedArr.map((item) => {
                        return {
                          ...item,
                          valueFound: false,
                        };
                      });
                      setCurrentItems(newArr as any);
                      setValueFind('');
                      setValueReplace('');
                    }
                  }
                }}
              >
                Replace
              </Button>
            </div>
          ) : (
            ''
          )}
        </div>
        <div
          className=""
          style={{
            overflow: 'auto',
            width: '100%',
            // paddingTop: '20px',
            height: 'calc(100% - 72px)',
          }}
        >
          {data.length > 0 && (
            <>
              <table
                border={1}
                style={{ width: '100%', marginBottom: '20px', borderCollapse: 'collapse' }}
              >
                <thead>
                  <tr
                    style={{
                      background: '#fff',
                    }}
                  >
                    {keysArray.map((key: any, index: number) => {
                      if (key === 'error') return false;
                      const isObj = headerArray?.find((item) => item.key === key.key);
                      const Name = isObj ? isObj.title : key.key;

                      return (
                        <th
                          key={key.key}
                          style={{
                            padding: '2px 5px ',
                            background: '#f4f4f4',
                            borderTopLeftRadius: index === 0 ? '10px' : '0px',
                            borderTopRightRadius: index === keysArray.length - 1 ? '10px' : '',
                            height: '30px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '5px',
                              justifyContent: 'center',
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  margin: '0px',
                                }}
                              >
                                {Name}
                              </p>
                            </div>
                            <Popover
                              content={content}
                              title=""
                              overlayInnerStyle={{
                                maxHeight: '250px',
                                overflow: 'auto',
                              }}
                              open={selectedFilter.key === key.key}
                              trigger="click"
                              onOpenChange={() => {
                                setSelectedFilter('');
                              }}
                            >
                              <div
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  setSelectedFilter(isObj ? isObj : key);
                                }}
                              >
                                <DownOutlined
                                  size={12}
                                  style={{
                                    width: '12px',
                                    height: '12px',
                                  }}
                                />
                              </div>
                            </Popover>
                          </div>

                          {key?.validation ? (
                            <p
                              style={{
                                margin: '0px',
                                fontSize: '12px',
                                textTransform: 'capitalize',
                              }}
                            >
                              (
                              {key?.validation === 'string'
                                ? 'Text'
                                : key?.validation === 'number_0'
                                ? 'Number > 0'
                                : key?.validation === 'number_-1'
                                ? 'Number > -1'
                                : key?.validation === 'number_0_decimal'
                                ? 'Number [Integer]'
                                : key?.validation === 'number_integer'
                                ? 'Integer (Includes Zero)'
                                : key?.validation === 'whole_number'
                                ? 'Whole number'
                                : key?.validation}
                              )
                            </p>
                          ) : (
                            ''
                          )}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((row: any, rowIndex: number) => {
                    const globalIndex = offset + rowIndex;

                    return (
                      <tr key={globalIndex}>
                        {keysArray.map((key_: any) => {
                          const key = key_.key;
                          if (key === 'error') return false;
                          const isFiltered = keysArray.find((item: any) => key === item.key);
                          const isObj = headerArray
                            ? headerArray.find((item) => item.key === key)
                            : '';
                          const isShouldNumber = isObj
                            ? isObj?.type === 'integer'
                              ? true
                              : false
                            : false;
                          const isEnum = isObj ? (isObj?.type === 'enum' ? isObj.key : '') : '';
                          const isString = isObj ? (isObj?.type === 'string' ? isObj.key : '') : '';
                          const isBoolean = isObj
                            ? isObj?.type === 'boolean'
                              ? isObj?.key
                              : ''
                            : '';

                          const isLink = isObj ? isObj?.key?.includes('cbl_datasheet') : '';

                          const enumIs = headerArray?.find((item) => item.key === isEnum);
                          const enumArray = enumIs ? enumIs?.enum : [];
                          const validArr = checkEnum ? validationTypes2 : validationTypes1;
                          const isRequired = isObj?.required;

                          const isNotInEnum = enumIs
                            ? validateField(row[key], validationTypes2, enumArray)
                            : false;
                          const valueFound =
                            row[key] && valueFind
                              ? row?.valueFound
                                ? row[key] === valueFind
                                  ? true
                                  : false
                                : false
                              : false;

                          const isNowError = isNotInEnum
                            ? true
                            : row[key] === ''
                            ? isRequired
                              ? true
                              : false
                            : isFiltered?.validation
                            ? validateField(
                                isShouldNumber
                                  ? Number(row[key])
                                  : isString
                                  ? row[key]
                                  : isBoolean
                                  ? booleanConvert(row[key])
                                  : row[key],
                                isLink
                                  ? [
                                      {
                                        id: 1,
                                        name: 'Validate',
                                        value: 'link',
                                      },
                                    ]
                                  : !isShouldNumber
                                  ? [
                                      {
                                        id: 1,
                                        name: 'Validate',
                                        value: isObj?.type,
                                      },
                                    ]
                                  : validArr.filter(
                                      (ite_m) => ite_m?.value === isFiltered?.validation,
                                    ),
                                enumArray,
                              )
                            : false;

                          const isColor = rowIndex % 2 !== 0 ? '#e5e5e5' : '#fff';
                          return (
                            <td
                              key={key}
                              style={{
                                minWidth:
                                  key === 'Index'
                                    ? '70px'
                                    : key === 'Email' || key === 'Phone 1' || key === 'Website'
                                    ? '200px'
                                    : '150px',
                                padding: '0px',
                              }}
                            >
                              <div
                                style={{
                                  // border: '1px solid',
                                  padding: '5px 6px',
                                  // borderTop: rowIndex === 0 ? '1px solid #c1c1c1' : '0px',

                                  background: isNowError ? '#ffb9b9' : isColor,
                                  // borderBottom: '1px solid #c1c1c1',
                                }}
                              >
                                <input
                                  type="text"
                                  value={row[key]}
                                  onChange={(e) =>
                                    handleCellChange(globalIndex, key, e.target.value)
                                  }
                                  onBlur={(e) => handleCellChange(globalIndex, key, e.target.value)}
                                  style={{
                                    border: 'none',
                                    width: 'calc(100% - 6px)',
                                    background: valueFound ? 'yellow' : 'transparent',
                                    outline: '0px',
                                    fontSize: '12px',
                                  }}
                                />
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </div>
        <div
          style={{
            width: '100%',
            paddingTop: '10px',
            paddingBottom: '10px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {data.length > 0 ? (
            <Pagination
              current={currentPage}
              pageSize={itemsPerPage}
              total={data.length}
              onChange={handlePageChange}
              onShowSizeChange={handlePageSizeChange}
              showSizeChanger
              pageSizeOptions={pageSizeOptions.map((size) => size.toString())} // Convert to string for Ant Design
            />
          ) : (
            ''
          )}
        </div>
      </Modal>
    </div>
  );
};

// export default CSVReader;
const mapStateToProps = (state: AppState) => {
  const parts = getParts(state) || [];

  return {
    isLoading: isStateLoading(state.action, 'partItem'),
    parts: parts,
  };
};

const mapDispatchToProps = {
  // snackBarOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(CSVReader);
