import { JSONSchema7 } from 'json-schema';
import { useRef, useState } from 'react';
import { connect } from 'react-redux';

import { FormSchema } from '~/components/FormSchema/FormSchema';
import { wizardSetAttachmentList, wizardSetDrawingNotesData } from '~/store/actions/wizard/Action';
import { AppState } from '~/store/reducers';
import { DrawingNotes, Note } from '~/store/reducers/wizard/State';
import styles from './index.module.scss';
import { Button, Table, Tooltip } from 'antd';
import { DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { putAssemblyUpload } from '~/api/AuthorizedPuts';
import { useAppDispatch } from '~/store/hooks';
import { deleteAssemblyAttachments } from '~/api/AuthorizedDeletes';

type PropsFromState = {
  drawingNotes: DrawingNotes;
  attachment: any;
  assemblyid: any;
  authenticated: boolean;
};
interface DataType {
  name: string;
  url: string;
}

type PropsFromDispatch = {
  wizardSetDrawingNotesData: typeof wizardSetDrawingNotesData;
  wizardSetAttachmentList: typeof wizardSetAttachmentList;
};

type WizardDwgNotesProps = PropsFromState & PropsFromDispatch;

type FormData = {
  formData: {
    drawingNotes: Note[];
  };
};

const WizardDwgNotes = ({
  drawingNotes,
  wizardSetDrawingNotesData,
  attachment,
  assemblyid,
  authenticated,
}: WizardDwgNotesProps) => {
  const dispatch = useAppDispatch();

  const [data, setData] = useState<Note[]>(drawingNotes.data ?? []);
  // const [attachdata, setAttachData] = useState(attachment);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  // console.log(assemblyid, 'assembly id');

  const handleChange = (data: FormData) => {
    const formData: Note[] = [...data.formData.drawingNotes];
    setData(formData);
    wizardSetDrawingNotesData(formData);
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;
    const fileArray = Array.from(files);
    const formData = new FormData();

    fileArray.forEach((file) => {
      formData.append('_fileName', file);
    });

    putAssemblyUpload(`${assemblyid}/upload`, formData)
      .then((response) => {
        dispatch(wizardSetAttachmentList(response.attchfile));
        // console.log('Upload successful:', response.attchfile);
      })
      .catch((error) => console.error('Upload failed:', error));

    // console.log(files, 'new file selected');
  };
  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleDelete = (url: string) => {
    deleteAssemblyAttachments(`${assemblyid}/delete-file?file_url=${url}`).then((response) => {
      dispatch(wizardSetAttachmentList(response.attchfile));
    });
  };
  const renderForm = () => {
    const schema: JSONSchema7 = {
      properties: {
        drawingNotes: {
          items: {
            properties: {
              name: {
                type: 'string',
                title: 'Name',
                default: '',
              },
              description: {
                type: 'string',
                title: 'Description',
                default: '',
              },
            },
            additionalProperties: false,
            type: 'object',
          },
          type: 'array',
          title: 'Drawing Notes',
        },
      },
      additionalProperties: false,
      type: 'object',
    };

    const formData = { drawingNotes: data || [] };

    return (
      <FormSchema
        cancelAction={() => console.log('cancel call')}
        confirmAction={() => console.log('confirm call')}
        formData={formData}
        schema={schema}
        showActions={false}
        onChange={(formData) => handleChange(formData)}
      />
    );
  };
  const columns: ColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '',
      key: 'view',
      render: (record: DataType) => (
        <Button
          icon={<EyeOutlined />}
          onClick={() => {
            if (record.url) {
              window.open(record.url, '_blank');
            } else {
              console.error('No URL available for this record');
            }
          }}
        >
          View
        </Button>
      ),
    },

    {
      title: 'Action',
      key: 'action',
      render: (record: DataType) => (
        <Button
          icon={<DeleteOutlined />}
          onClick={() => {
            handleDelete(record.url);
          }}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="wizard_drawing_notes">{renderForm()}</div>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.left}>
            <h3>Attachments</h3>
          </div>
          <div className={styles.right}>
            <input
              type="file"
              multiple
              accept="application/pdf"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <Tooltip
              title={
                authenticated === false
                  ? 'Please login first'
                  : !assemblyid
                  ? 'Assembly is not saved'
                  : ''
              }
            >
              <Button
                icon={<PlusOutlined />}
                disabled={!assemblyid}
                onClick={handleUploadClick}
                type="primary"
              >
                Upload
              </Button>
            </Tooltip>
          </div>
        </div>
        <Table dataSource={attachment} columns={columns} rowKey="id" />
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    drawingNotes: state.assemblyWizard.drawingNotes,
    attachment: state.assemblyWizard.attchfile,
    assemblyid: state.assemblyWizard.id,
    authenticated: state.data.auth.authenticated,
  };
};

const mapDispatchToProps = {
  wizardSetDrawingNotesData,
  wizardSetAttachmentList,
};

export default connect(mapStateToProps, mapDispatchToProps)(WizardDwgNotes);
